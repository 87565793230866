<template>
    <div>
        <div class="item" v-if="item">
     
          <div class="shop">

              <div class="shop-name">{{item.sellShopName}}</div>

              <div v-if="item.refundStatus==='0' &&item.refundStatus!=null">

                        <div class="status" v-if="item.isPay=='0'&&item.status!=='5'&&timer">待付款</div>
                        <div class="status" v-if="item.status==null&&!timer">支付超时</div>
                        <div class="status" v-if="item.status=='waitsellersend'">待发货</div>
                        <div class="status" v-if="item.status=='waitbuyerreceive'">待收货</div>
                        <div class="status" v-if="item.status=='confirm_goods_and_has_subsidy'">已完成</div>
                        <div class="status" v-if="item.status=='5'">已取消</div>
                       
              </div>

                        <div class="status" v-if="item.refundStatus==='refundsuccess'">退款成功</div>
                        <div class="status" v-if="item.refundStatus!=null&&item.refundStatus!='refundsuccess'&&item.refundStatus!='0'">退款中</div>
            

          </div>
            <div class="box">

                <img  :src="item.picUrl" @click="$router.push({path:'/makerOrderDetail',query:{id:item.id}})"> 
                <div class="goods-info">


                          <div class="name"  @click="$router.push({path:'/makerOrderDetail',query:{id:item.id}})">{{item.spuName}}</div>
                          <div class="lable"  @click="$router.push({path:'/makerOrderDetail',query:{id:item.id}})"><span style="display:flex;">规格：<span style="flex:1;overflow:auto;">{{item.specInfo||'无'}}</span>  </span> <span>x{{item.quantity}}</span></div>
                          <div class="name" v-if="item.refundStatus=='refundsuccess'">退款：￥{{item.paymentPrice}}</div>
                    


                </div>
            </div>
            <div class="price"> <span class="dd">共{{item.quantity}}件商品  合计:</span> <p><span>￥</span>{{item.paymentPrice+item.freight}}</p> </div>
            <div class="tuikuan" v-if="item.refundStatus =='refundsuccess'">
                退款成功  退款成功￥{{item.paymentPrice}}元
            </div>
            <div class="btns">


                <div class="btn1" v-if="item.isPay==='0'&&item.status==null" @click="cancel(item,5)">取消订单</div>
      <!-- v-if="(item.refundStatus==='waitbuyersend')" -->
                <div class="btn1"  v-if="(item.refundStatus==='waitbuyersend')" @click="$router.push({path:'makerRefundGoodss',query:{id:item.refundId}})">寄回商品</div>

                <div class="btn1" v-if="(item.status==='success'||item.status=='waitsellersend'||item.status=='waitbuyerreceive')&&item.refundStatus==='0'" @click="$router.push({path:'makerOrderRefund',query:{id:item.id}})">申请退款</div>
                <!-- <div class="btn1"  @click="$router.push({path:'makerOrderRefund',query:{id:item.id}})">申请退款</div> -->



                <div class="btn1" v-if="item.status==='confirm_goods_and_has_subsidy'||item.refundStatus==='2'||item.status=='5'" @click="$emit('delOneGoods' ,{id:item.id,index,index}) ">删除订单</div>


                <div class="btn2" v-if="item.status=='5'"   @click="$router.push({  path:'makerOrderDetail', query:{ id:item.id} })">查看详情</div>
               
                <div class="btn1" v-if="item.refundStatus!=null&&item.refundStatus!='0'" @click="$router.push({path:'makerRefundDetail',query:{price:item.paymentPrice,orderNo:item.refundId }})">售后详情</div>
               

                <div class="btn2" v-if="item.isPay==='0' &&item.status!=='5'&&timer" @click="$emit('show',index)">付款</div>


                <!-- <div class="btn2" v-if="item.status==='waitbuyerreceive'&&item.refundStatus==='0'" @click="cancel(item,4)">确认收货</div> -->
                
            </div>

           
            
      </div>

  </div>
  
</template>

<script>
import { edit} from '../../../api/maker/order'

    export default {
        props:['item','index'],
       data(){ 
         return{ 
           timer:false,
           timerId:0,
           overtime:false
           
         }
       },
       created(){ 
         this.time()
         this.item.freight = Number(this.item.freight)
       },
        methods:{ 
            
            showPay(){ 
                this.$emit('pay', this.index);
            },
            showRefund(){ 
              this.$emit('open', this.index);
            },
                 // 取消订单
            cancel(item,type){ 

                edit({id:item.id,status:type+''}).then(res=>{ 

                    if(res.data.code===0){ 

                        item.status=type+''

                        this.$toast.success('取消成功')

                    }else{ 

                        this.$toast.fail('取消失败')

                    }
                })
            },
            // 获取过期时间
            time(){ 
                 let createT= new Date(this.item.createTime.replace(/-/ig,'/'))
                 if(Date.now()-createT-15*60*1000<0){ 
                   this.timer=true
                 }

                 if( !this.timer) return
                this.timerId= setInterval(() => {
                     if(Date.now()-createT-15*60*1000<0){ 
                   this.timer=true
                 }else{ 

                   this.timer=false
                   clearInterval(this.timerId)
                 }
                }, 1000);

                  console.log(this.timer);

            }
          
        }
    }
</script>

<style lang="scss" scoped>

</style>