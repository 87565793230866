<template>
    <div class="page">

        <Head :type="0">

            <template #title>

                <div @click="rollTop">我的订单</div>

            </template>

        </Head>

        <div class="nav">
            <div class="list">

                <div class="border" :style="{left:left+'px'}" />
                <div class="list-box">

                    <div v-for="item,index in list " :key="item" @click="showIndex(index)"
                        :class="['list-item', {'color':index-type==0}]">{{item}}</div>

                </div>
            </div>
        </div>
        <!-- 上拉加载 -->
        <van-list :immediate-check="false" v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">

            <div class="data" v-if="mall">

                <Item v-for="item ,index in mall" :key="item.id+type+index" v-show="
                                    type==0||type==null || (type==1&&item.isRefund=='0'&&item.status!='5')
                                    || (type==2&&item.status==status[type]&&item.isRefund=='0')
                                    || (type==3&&item.status==status[type]&&item.isRefund=='0')
                                    
                                    " :item="item" :index="index" @delOneGoods="delOneGoods" @show=showPay> </Item>
                <!-- <div class="item" v-for="item ,index in mall" :key="item.id+type+Date.now()" v-show="
                                    type==0||type==null || (type==1&&item.isRefund=='0'&&item.status!='5')
                                    || (type==2&&item.status==status[type]&&item.isRefund=='0')
                                    || (type==3&&item.status==status[type]&&item.isRefund=='0')
                                    
                                    " >
                                        <div class="shop">
                                            <div class="shop-name">{{item.sellShopName}}</div>
                                            <div v-if="item.refundStatus==='0' &&item.refundStatus!=null">
                                                        <div class="status" v-if="item.isPay=='0'&&item.status!=='5'">待付款</div>
                                                        <div class="status" v-if="item.status=='waitsellersend'">待发货</div>
                                                        <div class="status" v-if="item.status=='waitbuyerreceive'">待收货</div>
                                                        <div class="status" v-if="item.status=='confirm_goods_and_has_subsidy'">已完成</div>
                                                        <div class="status" v-if="item.status=='5'">已取消</div>
                                            </div>

                                                        <div class="status" v-if="item.refundStatus==='refundsuccess'">退款成功</div>
                                                        <div class="status" v-if="item.refundStatus!=null&&item.refundStatus!='refundsuccess'&&item.refundStatus!='0'">退款中</div>
                                            

                                        </div>
                                            <div class="box">
                                                <img  :src="item.picUrl" @click="$router.push({path:'/makerOrderDetail',query:{id:item.id}})"> 
                                                
                                                

                                            

                                                <div class="goods-info">
                                                    <div class="name">{{item.spuName}}</div>
                                                    <div class="lable"><span>规格：{{item.specInfo||'无'}} </span> <span>x{{item.quantity}}</span></div>
                                                    <div class="name" v-if="item.refundStatus=='refundsuccess'">退款：￥{{item.paymentPrice}}</div>
                                                    
                                                </div>
                                            </div>
                                            <div class="price"> <span class="dd">共{{item.quantity}}件商品  合计:</span> <p><span>￥</span>{{item.paymentPrice}}</p> </div>
                                            <div class="tuikuan" v-if="item.refundStatus =='refundsuccess'">
                                                退款成功  退款成功￥{{item.paymentPrice}}元
                                            </div>
                                            <div class="btns">


                                                <div class="btn1" v-if="item.isPay==='0'&&item.status==null" @click="cancel(item,5)">取消订单</div>


                                                <div class="btn1" v-if="(item.status==='success'||item.status=='waitsellersend'||item.status=='waitbuyerreceive')&&item.refundStatus==='0'" @click="$router.push({path:'makerOrderRefund',query:{id:item.id}})">申请退款</div> -->
                <!-- <div class="btn1"  @click="$router.push({path:'makerOrderRefund',query:{id:item.id}})">申请退款</div> -->



                <!-- <div class="btn1" v-if="item.status==='confirm_goods_and_has_subsidy'||item.refundStatus==='2'||item.status=='5'" @click="delOneGoods(item.id,index)">删除订单</div>


                                                <div class="btn2" v-if="item.status=='5'"   @click="$router.push({  path:'makerOrderDetail', query:{ id:item.id} })">查看详情</div>
                                            
                                                <div class="btn1" v-if="item.refundStatus!=null&&item.refundStatus!='0'" @click="$router.push({path:'makerRefundDetail',query:{price:item.paymentPrice,orderNo:item.id }})">查看详情</div>
                                            

                                                <div class="btn2" v-if="item.isPay==='0' &&item.status!=='5'" @click="showPaul=true,number=index,payType='1'">付款</div> -->


                <!-- <div class="btn2" v-if="item.status==='waitbuyerreceive'&&item.refundStatus==='0'" @click="cancel(item,4)">确认收货</div> -->

                <!-- </div>

                                        
                                            
                                    </div> -->

            </div>

        </van-list>

        <van-action-sheet v-model="showPaul" title="支付">
            <div class="content">

                <van-radio-group v-model="payType">
                    <!-- <div class="qianbao">
                        <div class="left">
                             <img src="../../../assets/maker/qianbao.png" alt="" /> 钱包支付
                        </div> 
                        <van-switch v-model="checked" size="0.5rem" /> 

                     </div> -->
                    <!-- <span class="line"></span> -->

                    <van-cell-group>



                        <van-cell clickable @click="payType = '1'">
                            <template #title>
                                <img class="img" src="../../../../public/img/pay/alipay-icon.png" alt />
                                <span class="custom-title">支付宝</span>
                            </template>
                            <template #right-icon>
                                <van-radio name="1" />
                            </template>
                        </van-cell>
                        <span class="line"></span>

                        <van-cell clickable @click="payType = '2'">
                            <template #title>
                                <img class="img" src="../../../../public/img/pay/weChat-icon.png" alt />
                                <span class="custom-title">微信</span>
                            </template>
                            <template #right-icon>
                                <van-radio name="2" />
                            </template>
                        </van-cell>
                    </van-cell-group>
                </van-radio-group>
                <div class="pay" @click="pay()">支付</div>
            </div>

        </van-action-sheet>
        <!-- <van-popup v-model="show" round position="bottom" :style="{ height: '30%' }" /> -->
    </div>
</template>

<script>
    import Head from '../../../components/head/index.vue'
    import * as  order from '../../../api/maker/order';
    import { wxPay, aliPay } from "../../../api/maker/pay";
    import Item from './order-item'
    import smoothscroll from 'smoothscroll-polyfill'// 滚动动画
    export default {
        data() {
            return {
                show: false,
                showRefund: false,
                list: ["全部", "待付款", "待发货", "待收货"],
                status: [null, 'confirm_goods_and_has_subsidy', 'waitsellersend', 'waitbuyerreceive', 'cancel'],
                loading: false,
                finished: true,
                size: 20,
                page: 1,
                mall: [],
                type: 0,
                payType: '1', //支付方式
                showPaul: false, // 显示支付面板
                checked: true,
                number: null,
                data: null,
                good: null,
                left: 1.15 * window.screen.width / 10,
                sessionSt: null
            }
        },
        created() {

            const session = this.$route.query.session || 'f3a881c1-5e5b-4a3e-92d1-970a7cacce81'
            this.sessionSt = JSON.parse(sessionStorage.getItem('pageInfo')) ?? null
            if (this.sessionSt && this.sessionSt.scrollLeft?.true) {
                this.sessionSt = null


            }
            if (session) {
                this.$store.commit('setSession', session)
            }

            this.type = this.$route.query.type ?? null
            //判断是否 点击固定 状态进入

            if (Object.prototype.hasOwnProperty.call(this.$route.query, 'type') && this.sessionSt == null)
                this.$nextTick(() => {
                    this.type = this.$route.query.type
                    console.log(this.type, 'type');
                    this.showIndex(this.type)
                });



            window.closePage = this.closePage



            if (this.sessionSt != null) {

                sessionStorage.removeItem('pageInfo')

                this.showIndex(parseInt(this.sessionSt.type), 1)

            }

            if (!Object.prototype.hasOwnProperty.call(this.$route.query, 'type') && this.sessionSt == null) {

                this.getdata()

            }

        },
        mounted() {
            this.setRem()


        },
        methods: {



            onLoad() {
                console.log('上拉加载')
                this.page++
                this.getdata()
            },

            getdata(pageSize = 1) {
                if (!this.type) {

                    this.type = null

                }


                this.finished = true
                order.orderInfo({ size: this.size * pageSize, current: this.page, status: this.type != 1 ? this.status[this.type] : null, isPay: this.type == 1 ? '0' : null }).then((res) => {


                    if (res.data.code === 0) {

                        if (this.sessionSt != null) {

                            this.page = this.sessionSt.page

                        }
                        this.mall = [...this.mall, ...res.data.data.records]
                    }


                    if (this.sessionSt != null) {

                        this.$nextTick(() => {
                            document.querySelector('.van-list').scrollTo(0, parseInt(this.sessionSt.scrollTop))
                        });

                    }


                    if (!(res.data.data.records.length < this.size)) {
                        this.finished = false
                    }





                }).finally(() => {
                    this.loading = false
                    if (this.sessionSt != null) {
                        this.page = Number(this.sessionSt.page)
                        this.sessionSt = null
                        sessionStorage.removeItem('pageInfo')
                    }
                })


            },
            showIndex(index, num) {


                //    this.$nextTick( ()=>{ 


                //     });



                this.type = index
                this.finished = false
                if (this.sessionSt != null) {

                    this.$nextTick(() => {
                        if (parseInt(this.sessionSt.type)) {
                            this.left = document.querySelectorAll('.list-item')[index].offsetLeft + (document.querySelectorAll('.list-item')[index].offsetWidth) / 2 - (0.133333 * window.screen.width / 7)

                        }



                    });

                } else {


                    this.page = 1
                    if (!num) {

                        this.$nextTick(() => {
                            this.left = document.querySelectorAll('.list-item')[index].offsetLeft + (document.querySelectorAll('.list-item')[index].offsetWidth) / 2 - (0.133333 * window.screen.width / 7)

                        });



                    }




                }
                this.change()
            },


            delOneGoods(data) {

                order.delOrder(data.id).then(res => {

                    if (res.data.code === 0) {

                        this.$toast.success('删除成功')

                        this.mall.splice(data.index, 1)

                    } else {

                        this.$toast.fail('删除失败')
                    }

                })
            },
            refundChange() {

                this.showRefund = false
            },




            change() {


                this.mall = []

                if (this.sessionSt != null) {

                    this.getdata(Number(this.sessionSt.page))

                } else {
                    console.log(222);

                    this.getdata()
                }
            },


            pay() {

                let arr = []

                arr.push(this.mall[this.number].id)

                if (this.payType === '1') {

                    aliPay(arr).then(res => {

                        this.openPay(res, '2');

                    })

                    return

                }

                wxPay(arr).then(res => {

                    if (res.data.code === 0) {

                        this.openPay(res, '1');

                    }
                })



            },

            rollTop() {

                smoothscroll.polyfill()

                document.querySelector('.van-list').scrollTo({ top: 0, behavior: 'smooth' })

            },
            closePage(data) {

                this.getdata()

                if (data == 1) return

                if (this.type) {


                    this.mall.splice(this.number, 1)
                    return

                }

                this.$set(this.mall[this.number], 'isPay', '1');

                this.$set(this.mall[this.number], 'status', 'waitsellersend');

            },
            showPay(index) {

                this.number = index,

                    this.showPaul = true


            }


        },
        // watch:{ 
        //     type:function(){ 

        //             smoothscroll.polyfill()
        //                     document.querySelector('.list').scrollTo({ 
        //                     left:document.querySelectorAll('.list-item')[this.type?? 0].offsetLeft - (document.body.getBoundingClientRect().width/2) +
        //                                                         document.querySelectorAll('.list-item')[this.type?? 0].getBoundingClientRect().width/2,
        //                     top:0,
        //                      behavior:'smooth'
        //            })
        //     }
        // },

        components: { Head, Item },

        beforeRouteLeave(to, from, next) {

            sessionStorage.setItem('pageInfo', JSON.stringify({ page: this.page, type: this.type + '', scrollTop: document.querySelector('.van-list').scrollTop + '' }))

            next()
        }
    }
</script>

<style lang="less" scoped>
    .page {
        height: 100%;
        width: 100%;
        position: fixed;
        left: 0;
        right: 0;
        background-color: rgba(255, 255, 255, 0.5);
        box-sizing: border-box;
        font-family: PingFangSC-Medium, PingFang SC;
        display: flex;
        flex-direction: column;
        background-color: white;


        .list::-webkit-scrollbar {
            display: none;
        }

        .nav {
            z-index: 100;
            width: 100%;
            background-color: white;
        }

        .list {
            width: 100%;
            position: relative;
            background-color: white;
            box-shadow: 0px 10px 10px 0px #ccc;



            .list-box {

                display: flex;
                justify-content: space-around;
                padding: .266667rem;
                width: 100%;


                // justify-content: space-around;
                .list-item {
                    padding: 0 .4rem;
                    font-size: .373333rem;
                }
            }

            .border {
                transition: 0.1s linear;
                bottom: .066667rem;
                position: absolute;
                width: .4rem;
                height: .08rem;
                border-radius: .08rem;
                background-color: #FF5200;
            }
        }

        .van-list::-webkit-scrollbar {
            display: none;
        }

        /deep/ .van-list {
            flex: 1;
            background-color: #f6f6f6;
            overflow: auto;

            .data {
                padding: .66667rem .133333rem;
                // padding-bottom: constant(safe-area-inset-bottom);
                // padding-bottom: env(safe-area-inset-bottom);

                .item {
                    border-radius: .266667rem;
                    background-color: white;
                    padding: .266667rem;
                    // padding-bottom: 0;
                    box-sizing: border-box;
                    margin-bottom: .266667rem;

                    .shop {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-size: .373333rem;
                        margin-bottom: .48rem;

                        .shop-name {
                            color: #333;
                        }

                        .status {
                            color: #FF5200;
                        }
                    }

                    .box {
                        display: flex;
                        font-size: .32rem;
                        margin-bottom: .266667rem;

                        img {
                            width: 2.133333rem;
                            height: 2.133333rem;
                            margin-right: .266667rem;
                            border-radius: .266667rem;
                        }

                        .goods-info {
                            margin-bottom: .266667rem;

                            .name {
                                font-family: PingFangSC-Medium, PingFang SC;
                                color: #333333;
                                height: .906667rem;
                                overflow: hidden;
                                margin-bottom: .133333rem;

                            }

                            .lable {

                                color: #999999;
                                margin-bottom: .16rem;
                                display: flex;
                                justify-content: space-between;

                                span {
                                    display: block;
                                }
                            }
                        }



                    }

                    .price {
                        display: flex;
                        justify-content: flex-end;
                        margin-bottom: .266667rem;
                        font-size: .373333rem;
                        color: #333;

                        p {
                            color: #a80000;
                            margin-left: .266667rem;
                            font-size: .48rem;
                            display: flex;
                            justify-content: center;
                            line-height: 0.55rem;

                            span {
                                font-size: .373333rem;
                            }
                        }
                    }

                    .tuikuan {

                        background: #F6F6F6;
                        border-radius: .133333rem;
                        width: 9.146667rem;
                        height: .826667rem;
                        margin: 0 auto;
                        display: flex;
                        align-items: center;
                        padding-left: .853333rem;
                        box-sizing: border-box;
                    }

                    .btns {
                        margin-top: .266667rem;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        // padding-bottom:constant(safe-area-inset-bottom) ;
                        // padding-bottom:env(safe-area-inset-bottom) ;
                        font-size: .373333rem;

                        .btn1 {
                            width: 2.13333rem;
                            height: .746667rem;
                            border: 1px solid #999;
                            border-radius: .266667rem;
                            margin-left: .266667rem;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: #999;
                        }

                        .btn2 {
                            width: 2.293333rem;
                            height: .8rem;

                            border-radius: .266667rem;
                            margin-left: .266667rem;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: white;
                            background-image: linear-gradient(to right, #FF7200, #FF3C00);
                        }
                    }
                }
            }
        }
    }

    .content {
        padding: .213333rem .213333rem 0;
        height: 6.666667rem;

        img {
            width: .666667rem;
            height: .666667rem;
            margin-right: .266667rem;
        }

        .qianbao {
            padding: .266667rem 0;
            margin: 0 .4rem;
            flex: 1;
            display: flex;
            align-items: center;

            justify-content: space-between;
            border-bottom: 1px solid #f6f6f6;
        }

        .left {
            display: flex;
            align-items: center;

        }

        /deep/ .van-cell__title {
            display: flex;
            align-content: center;
        }

        .pay {
            width: 8.24rem;
            height: 0.96rem;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            font-size: 0.4rem;
            font-weight: 500;
            background-color: #e64d42;
            margin: 1.033333rem auto 1.033333rem;
            border-radius: 0.56rem;
        }
    }

    /deep/ .van-overlay {
        transition: 0.5s linear;
        background-color: rgba(0, 0, 0, .5) !important;
    }

    /deep/ [class*=van-hairline]::after {
        border: none !important;
        ;
    }

    .color {

        color: #FF5200;
    }

    /deep/ .van-cell__title {
        border-radius: .266667rem;
        display: flex;
        align-items: center;
        color: #333;
        font-size: .373333rem;
        font-family: PingFangSC-Regular, PingFang SC;

        img {
            width: .64rem;
            height: .64rem;
        }
    }
</style>